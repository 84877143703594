import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, functions } from "../database";
import { httpsCallable } from "firebase/functions";

// Add web contact message to Firestore
const addContactMessage = async (message) => {
    const { firstName, lastName, email, messageBody, messageSubject, phoneNumber } = message;
    const collectionRef = collection(db, 'webform');
    await addDoc(collectionRef, {
        contactEmail: email,
        message: messageBody,
        name: firstName + ' ' + lastName,
        phoneNumber: phoneNumber,
        subject: messageSubject,
        createdAt: serverTimestamp()
    }).then(() => {
        console.log('Message added to Firestore');
        return true;
    }).catch((error) => {
        console.log('Error adding message to Firestore: ' + error);
        return false;
    });
}

const sendCareerApplication = async (application) => {
    const { firstName, lastName, email, phoneNumber, message, resume } = application;
    // Upload resume to Firebase Storage and get the URL

    const collectionRef = collection(db, 'webApplications');
    await addDoc(collectionRef, {
        contactEmail: email,
        message: message,
        name: firstName + ' ' + lastName,
        phoneNumber: phoneNumber,
        resume: resume,
        createdAt: serverTimestamp()
    }).then(() => {
        console.log('Application added to Firestore');
        return true;
    }).catch((error) => {
        console.log('Error adding application to Firestore: ' + error);
        return false;
    });
}

const subscribeToEmailList = async (userInfo) => {
    // Firebase function to add user to email list
    const { firstName, lastName, email } = userInfo;
    const addSubscriber = httpsCallable(functions, 'addToEmailList');
    addSubscriber({ firstName: firstName, lastName: lastName, email: email }).then((result) => {
        console.log(result);
        return true;
    }).catch((error) => {
        console.log(error);
        return false;
    });
}

export {
    addContactMessage,
    subscribeToEmailList,
}
import { initPlasmicLoader } from "@plasmicapp/loader-react";
export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: "j9wcRaFEt7g9pDHFN25DeM",  // ID of a project you are using
      token: "poRSGltOmX8xzZmdkAAdixVlptxj0zMxkyNMbLjiiZ0Ir3wOxcRGdkabIJatubw2KtY35E30tMPBMBbSwhduA"  // API token for that project
    }
  ],
  // Fetches the latest revisions, whether or not they were unpublished!
  // Disable for production to ensure you render only published changes.
  preview: false,
});